import { useEffect, useState } from "react";
// store
import useStore from "@services/store/store";
// components
import CallItem from "./CallItem";
// css
import "./callList.css";

export default function CallList() {
  // parse list of calls from local storage
  const { calls, setCalls } = useStore((state) => state);

  useEffect(() => {
    const calls = JSON.parse(localStorage.getItem("waiterCall")) || [];
    setCalls(calls);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h2 className="mt-[20px] text-[26px] font-[600]">
        {calls.length > 0 ? "Вызовы на столы:" : "Нет вызовов на столы"}
      </h2>

      <div className="call-list items-center justify-center">
        {calls.map((call, index) => (
          <CallItem key={index} call={call} />
        ))}
      </div>
    </>
  );
}
