// components
import Auth from "../components/Auth/Auth";

export default function HomePage() {
  return (
    <div>
      <Auth />
    </div>
  );
}
