// components
import CallList from "@components/CallList/CallList";
import Socket from "@components/Socket/Socket";

export default function HomePage() {
  return (
    <div className="flex flex-col items-center justify-center">
      <CallList />
      <Socket />
    </div>
  );
}
