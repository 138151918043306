import axios from "axios";

const getBaseUrl = () => {
  // Access the base URL from the environment variable
  return process.env.REACT_APP_API_URL; // Default fallback URL
};

const fetchData = async ({
  path,
  method = "GET",
  data = null,
  headers = {},
  params,
  auth = false,
}) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}${path}`;
  const token = localStorage.getItem("token");

  const headersMerged = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
    // Authorization header with the token
    ...(auth && token && { Authorization: `Bearer ${token}` }),
    // Add any other headers as needed
    ...headers,
  };

  const paramsMerged = {
    ...params,
  };

  try {
    const response = await axios({
      url,
      method,
      data,
      headers: headersMerged,
      params: paramsMerged,
    });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle errors appropriately (e.g., throw an error or display a message)
  }
};

export default fetchData;
