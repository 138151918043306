import { useEffect, useState } from "react";
// store
import useStore from "@services/store/store";
// socket.io
import { io } from "socket.io-client";
import Modal from "react-modal";
import "./style.css";

export default function Socket() {
  const { user, setCalls } = useStore((state) => state);
  const [isModal, setIsModal] = useState(true);

  useEffect(() => {
    if (!user) return;
    const command = "prodOrderEvent";
    const audio = new Audio(require("../../assets/alert.mp3"));
    audio.volume = 1;

    const url = process.env.REACT_APP_SOCKET_URL;

    const socket_organization_id = user?.organization_id;

    const socket = io(`${url}?organization_id=${socket_organization_id}`);
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    socket.on("connect", () => {
      socket.emit("joinRoom", `room:${socket_organization_id}`);
    });

    socket.on("disconnect", (reason) => {
      console.log("disconnect");
    });

    // socket.on(`${command}:${socket_organization_id}`, handleOrderEvent);

    // on error
    socket.on("error", (error) => {
      console.log("error: ", error);
    });

    socket.on("waiterCall", (data) => {
      if (audio) {
        playAudioWithContext(audioContext);
        const waiterLocal =
          JSON.parse(localStorage.getItem("waiterCall")) || [];
        localStorage.setItem(
          "waiterCall",
          JSON.stringify([...waiterLocal, data])
        );

        setCalls([...waiterLocal, data]);
      }
    });
    setTimeout(() => {
      setIsModal(false);
    }, 3000);

    return () => {
      socket.disconnect();
    };
  }, [user]);

  // const handleOrderEvent = (data) => {
  //   const audio = new Audio(require("../../assets/alert.mp3"));
  //   audio.volume = 1;

  //   if (audio) {
  //     audio
  //       .play()
  //       .then(() => console.log("Audio played successfully"))
  //       .catch((err) => console.error("Error playing audio:", err));
  //   }

  //   const waiterLocal = JSON.parse(localStorage.getItem("waiterCall")) || [];
  //   localStorage.setItem("waiterCall", JSON.stringify([...waiterLocal, data]));
  //   setCalls([...waiterLocal, data]);
  // };

  const playAudioWithContext = (audioContext) => {
    fetch(require("../../assets/alert.mp3"))
      .then((res) => res.arrayBuffer())
      .then((data) => {
        audioContext.decodeAudioData(data, (buffer) => {
          const source = audioContext.createBufferSource();
          source.buffer = buffer;
          source.connect(audioContext.destination);
          source.start(0);
        });
      })
      .catch((err) => console.error("Error fetching or playing audio:", err));
  };

  return (
    <div className="socket-modal">
      <Modal ariaHideApp={false} isOpen={isModal} contentLabel="Example Modal">
        <div
          onClick={() => setIsModal(false)}
          className="flex flex-row items-center justify-center absolute top-0 right-0 m-[10px] cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="25"
            height="25"
            viewBox="0 0 50 50"
          >
            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
          </svg>
        </div>
        <div className="box">
          <h1 className="title">
            Assalomu alaykum, bu yerda mijozlar chaqirgan stol raqami ko'rinadi{" "}
          </h1>
          <button
            className="button"
            onClick={() => {
              setIsModal(false);
            }}
          >
            Tushunarli
          </button>
        </div>
      </Modal>
    </div>
  );
}
